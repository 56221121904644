import React from "react"
import { Link } from "gatsby"
import { linkResolver } from "./_helpers/linkResolver"

const CustomLink = (props) => {
  const {link, children} = props;
  const isExternal = (link.link_type === 'Web');
  const isMedia = !!(link.url && link.url.indexOf(".pdf") > -1);
  if (!link.target) link.target = '_blank'

  return (
    <>
      {(isExternal || isMedia) ?
        (
          <a href={link.url} target={link.target} {...props} rel="noopener noreferrer">{children}</a>
        ) : (
          !isExternal && (
            <Link to={linkResolver(link)} {...props}>{children}</Link>
          )
        )
      }
    </>
  )
}

export default CustomLink
